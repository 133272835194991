import { isDefined } from "@clipboard-health/util-ts";
import { type StreaksSettings } from "@src/appV2/Streaks/api/useGetStreaksSettings";
import { type Streak, type StreakProgress } from "@src/appV2/Streaks/types";
import {
  addWeeks,
  areIntervalsOverlapping,
  differenceInMinutes,
  parseISO,
  setDay,
  startOfDay,
  startOfWeek,
} from "date-fns";
import { format, zonedTimeToUtc } from "date-fns-tz";
import { isEmpty } from "lodash";

export function formatPayBoostPercentage(payBoostPercentage: number): string {
  return `${Number((payBoostPercentage * 100).toFixed(2))}%`;
}

interface GenerateStreakAlertMessageParams {
  shiftWeekActiveStreak?: Streak;
  shiftStartTime: Date;
  shiftEndTime: Date;
  streakSettings: StreaksSettings;
  inProgressStreak?: StreakProgress;
  onlyCheckShiftWeekActiveStreak: boolean;
  isOpenShift?: boolean;
  isForFacilityOpenShift?: boolean;
  currentWeekActiveStreak?: Streak;
  shiftIsQualifiesForStreakBonus: boolean;
}
type CanActivateInProgressPayBoostParams = Omit<
  GenerateStreakAlertMessageParams,
  "onlyCheckActiveStreak" | "activeStreak"
>;

export function isDateWithinStreakValidity(
  date: string | Date,
  streak?: Streak | StreakProgress
): boolean {
  if (!isDefined(streak)) {
    return false;
  }

  const streakStart = parseISO(streak.attributes.validityInterval.from);
  const streakEnd = parseISO(streak.attributes.validityInterval.to);
  const parsedDate = typeof date === "string" ? parseISO(date) : date;
  return (
    parsedDate.getTime() >= streakStart.getTime() && parsedDate.getTime() <= streakEnd.getTime()
  );
}

export function isStreakWithPayBoostMultiplier(streak?: Streak): boolean {
  return (
    isDefined(streak) &&
    streak.attributes.payBoostEnabled &&
    streak.attributes.payBoostPercentage > 0
  );
}

export function canActivateInProgressPayBoost(
  params: CanActivateInProgressPayBoostParams
): boolean {
  const { streakSettings, inProgressStreak, shiftStartTime, shiftEndTime, isOpenShift } = params;
  const shiftIsOpenShift = isDefined(isOpenShift) && isOpenShift;

  const { verifiedHoursInStreakPeriod, unverifiedHoursInStreakPeriod } =
    inProgressStreak?.attributes ?? {
      verifiedHoursInStreakPeriod: 0,
      unverifiedHoursInStreakPeriod: 0,
    };

  const { minHoursForActiveStreak, payBoostPercentage } = streakSettings.attributes;

  const bookedHours = verifiedHoursInStreakPeriod + unverifiedHoursInStreakPeriod;
  const shiftLengthInHours = differenceInMinutes(shiftEndTime, shiftStartTime) / 60;
  const shiftStartsBeforeInProgressStreakPeriodEnd = isDateWithinStreakValidity(
    shiftStartTime,
    inProgressStreak
  );

  return (
    shiftIsOpenShift &&
    payBoostPercentage > 0 &&
    bookedHours < minHoursForActiveStreak &&
    shiftLengthInHours + bookedHours >= minHoursForActiveStreak &&
    shiftStartsBeforeInProgressStreakPeriodEnd
  );
}

interface IsShiftQualifiesForStreakBonusParams {
  shiftWeekActiveStreak?: Streak;
  shiftStartTime: Date;
}

export function isShiftQualifiesForStreakBonus(params: IsShiftQualifiesForStreakBonusParams) {
  const { shiftWeekActiveStreak, shiftStartTime } = params;
  return (
    isStreakWithPayBoostMultiplier(shiftWeekActiveStreak) &&
    isDateWithinStreakValidity(shiftStartTime, shiftWeekActiveStreak)
  );
}

export function generateStreakAlertMessage(
  params: GenerateStreakAlertMessageParams
): string | undefined {
  const {
    shiftWeekActiveStreak,
    shiftIsQualifiesForStreakBonus,
    onlyCheckShiftWeekActiveStreak,
    isOpenShift = false,
    currentWeekActiveStreak,
    isForFacilityOpenShift = false,
  } = params;
  const payBoostPercentage = shiftWeekActiveStreak?.attributes.payBoostPercentage ?? 0;
  const minHoursForActiveStreak = shiftWeekActiveStreak?.attributes.minHoursForActiveStreak ?? 0;

  if (shiftIsQualifiesForStreakBonus) {
    return `You're earning ${formatPayBoostPercentage(
      payBoostPercentage
    )} more on this shift because you worked more than ${minHoursForActiveStreak} hours last week at this workplace.`;
  }

  if (onlyCheckShiftWeekActiveStreak) {
    return undefined;
  }

  if (canActivateInProgressPayBoost(params) && !isForFacilityOpenShift) {
    return "Unlock the following week's Streak rewards at this workplace if you work this full shift!";
  }

  if (isDefined(currentWeekActiveStreak) && isOpenShift && !isForFacilityOpenShift) {
    return "You have a Streak at this workplace. Book ahead of time to lock in future Streak pay.";
  }

  return undefined;
}

interface CheckShiftHasStreakPayBoostParams {
  hcpStreaks: Streak[];
  shiftStart: string;
}

interface GetActiveStreakByDateParams {
  streaks: Streak[];
  date: string | Date;
}

export function getActiveStreakByDate(params: GetActiveStreakByDateParams): Streak | undefined {
  const { streaks, date } = params;

  for (const streak of streaks) {
    if (isDateWithinStreakValidity(date, streak) && isStreakWithPayBoostMultiplier(streak)) {
      return streak;
    }
  }

  return undefined;
}

export function getShiftStreak(params: CheckShiftHasStreakPayBoostParams): Streak | undefined {
  const { hcpStreaks, shiftStart } = params;
  return getActiveStreakByDate({ streaks: hcpStreaks, date: shiftStart });
}

export function checkShiftHasStreakPayBoost(params: CheckShiftHasStreakPayBoostParams) {
  const streak = getShiftStreak(params);
  return isDefined(streak);
}

export function getPayBoostActiveStreaks(activeStreaks: Streak[] | undefined): Streak[] {
  if (!isDefined(activeStreaks)) {
    return [];
  }

  return activeStreaks.filter((streak) => isStreakWithPayBoostMultiplier(streak));
}

export function filterStreaksByDateRange(params: { streaks: Streak[]; from?: Date; to?: Date }) {
  const { streaks, from, to } = params;
  if (isEmpty(streaks)) {
    return [];
  }

  const searchInterval = {
    start: from ?? new Date(),
    end: to ?? new Date(),
  };

  return streaks.filter((streak) => {
    return areIntervalsOverlapping(
      {
        start: parseISO(streak.attributes.validityInterval.from),
        end: parseISO(streak.attributes.validityInterval.to),
      },
      searchInterval
    );
  });
}

export function getCurrentActiveStreaks(activeStreaks: Streak[] | undefined) {
  return filterStreaksByDateRange({ streaks: activeStreaks ?? [] });
}

export function getFormattedStreaksPaymentDateInLocalTimezone(): string {
  let nextPaymentDate = new Date();
  // next week
  nextPaymentDate = addWeeks(nextPaymentDate, 1);
  // on tuesday
  nextPaymentDate = setDay(nextPaymentDate, 2);
  // at midnight PST
  nextPaymentDate = startOfDay(nextPaymentDate);

  const utcStreakStart = zonedTimeToUtc(nextPaymentDate, "America/Los_Angeles");

  return format(utcStreakStart, "p zzz EEEE, MM/dd");
}

export function getFormattedStreaksStartDateInLocalTimezone(): string {
  let nextStreakStartInPST = new Date();
  // next week
  nextStreakStartInPST = addWeeks(nextStreakStartInPST, 1);
  // on sunday
  nextStreakStartInPST = startOfWeek(nextStreakStartInPST);
  // at midnight
  nextStreakStartInPST = startOfDay(nextStreakStartInPST);

  const utcStreakStart = zonedTimeToUtc(nextStreakStartInPST, "America/Los_Angeles");

  return format(utcStreakStart, "EEEE p");
}
